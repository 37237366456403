const baseURL = "https://manage.letsdiskus.com";

//Services EndPoints
const serviceAutheticationURL = "/DAuth/";

//Admin Endpoint
const adminApi = "/DAdmin/";

//Excel Report
const excelReport = "/DReportDownload/";

//Services URL

//Login Api URL
const authenticationURL = baseURL + serviceAutheticationURL;

//Admin Api URL
const adminURL = baseURL + adminApi;

//Excel Report URL
const excelURL = baseURL + excelReport;

export { authenticationURL, adminURL, excelURL };
